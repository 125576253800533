.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

svg {
  width: inherit;
  height: inherit;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  /* background: rgba(56,180,10,0.5097748572085083); */
  background-image: linear-gradient(rgba(56,180,10,0.5321838207939426), rgba(10,103,91,0.2313434846594888), rgba(121,116,9,0.2865255574886205));
  /* background-image: linear-gradient(rgba(142,133,4,0.5097748572085083), rgba(56,180,10,0.5321838207939426)); */
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(rgba(142,133,4,0.5097748572085083), rgba(56,180,10,0.5321838207939426));
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(rgba(142,133,4,1), rgba(56,180,10,1));
}
